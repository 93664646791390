/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalSparePartDecision } from './externalSparePartDecision';
import { CreditNote } from './creditNote';
import { ErrorCodingsDescriptions } from './errorCodingsDescriptions';
import { InternalSparePartDecision } from './internalSparePartDecision';
import { Attachment } from './attachment';
import { ErrorCodings } from './errorCodings';
import { ClaimSibling } from './claimSibling';


export interface ClaimDecision { 
    
    claim_id: number;
    
    description?: string;
    
    claim_number_sp: string;
    
    claim_number_area?: string;
    
    claim_state: ClaimDecisionClaimState;
    
    claim_state_area?: ClaimDecisionClaimStateArea;
    
    claim_display_mode_sp: ClaimDecisionClaimDisplayModeSp;
    
    claim_display_mode_area: ClaimDecisionClaimDisplayModeArea;
    
    repair_date: string;
    
    first_installation_date?: string;
    
    operation_hours?: number;
    
    claim_number: string;
    
    claim_type: ClaimDecisionClaimType;
    
    service_partner_id: number;
    
    service_partner_name?: string;
    
    service_partner_city?: string;
    
    fault_serial_number?: string;
    
    error_pattern?: string;
    
    repair_description?: string;
    
    error_codings: ErrorCodings;
    
    description_addition?: string;
    
    decision?: string;
    
    claim_process?: ClaimDecisionClaimProcess;
    
    claim_process_area?: ClaimDecisionClaimProcessArea;
    
    is_created_on_behalf_of_sp: boolean;
    /**
     * Free text from GA requesting more information to be added to claim
     */
    
    required_information?: string;
    /**
     * Free text from SP, what information was added
     */
    
    additional_information?: string;
    
    attachments?: Array<Attachment>;
    
    company_attachments?: Array<Attachment>;
    
    sap_attachments?: Array<Attachment>;
    
    internal_spare_parts?: Array<InternalSparePartDecision>;
    
    external_spare_parts?: Array<ExternalSparePartDecision>;
    
    error_coding_descriptions: ErrorCodingsDescriptions;
    
    currency_ga?: string;
    
    currency_sp?: string;
    
    working_hours_sp: number;
    
    working_hour_price_sp: number;
    
    is_working_hour_price_sp_maintained: boolean;
    
    working_hours_ga: number;
    
    working_hour_price_ga: number;
    
    working_hours_approved_ga: number;
    
    working_hour_price_approved_ga: number;
    
    working_hours_approved_area: number;
    
    working_hour_price_approved_area: number;
    
    processing_fee_sp?: number;
    
    processing_fee_approved_ga?: number;
    
    processing_fee_ga?: number;
    
    processing_fee_approved_area?: number;
    
    processing_fee_decision?: string;
    
    processing_fee_approved_state_area?: ClaimDecisionProcessingFeeApprovedStateArea;
    
    processing_fee_approved_state_ga?: ClaimDecisionProcessingFeeApprovedStateGa;
    
    readonly total_working_price_sp: number;
    
    readonly total_working_price_ga: number;
    
    readonly total_working_price_approved_ga: number;
    
    readonly total_working_price_approved_area: number;
    
    created_on: Date;
    
    created_by: string;
    
    modified_on: Date;
    /**
     * Either the full name (if available) or username of user who modified claim lastly
     */
    
    modified_by: string;
    
    claim_display_state: ClaimDecisionClaimDisplayState;
    
    claim_display_state_area?: ClaimDecisionClaimDisplayStateArea;
    
    is_recent: boolean;
    
    sold_by_other_dealer: boolean;
    
    internal_note?: string;
    
    tracking_number?: string;
    
    recall_working_hours_modified_reason_ga?: string;
    
    recall_working_hours_modified_reason_sp?: string;
    
    submitted_to_ga_on?: Date;
    
    days_between_repair_and_submit: number;
    
    days_between_info_requested_and_info_given: number;
    
    days_between_material_requested_and_material_received: number;
    
    siblings?: Array<ClaimSibling>;
    
    credit_notes?: Array<CreditNote>;
    
    service_partner_notifications_enabled: boolean;
    
    case_number?: string;
    
    repair_time_wg?: number;
    
    repair_working_hours_modified_reason_ga?: string;
    
    repair_working_hours_modified_reason_sp?: string;
}
export enum ClaimDecisionClaimState {
    DraftServicePartner = 'DraftServicePartner',
    DraftGeneralAgent = 'DraftGeneralAgent',
    DraftDecisionGeneralAgent = 'DraftDecisionGeneralAgent',
    SentToGeneralAgent = 'SentToGeneralAgent',
    SentToArea = 'SentToArea',
    InformationGivenToGeneralAgent = 'InformationGivenToGeneralAgent',
    ReturnedToServicePartner = 'ReturnedToServicePartner',
    ReturnedToGeneralAgent = 'ReturnedToGeneralAgent',
    PartsRequestedFromServicePartner = 'PartsRequestedFromServicePartner',
    PartsRequestedFromGeneralAgent = 'PartsRequestedFromGeneralAgent',
    PartsSentToGeneralAgent = 'PartsSentToGeneralAgent',
    PartsSentToArea = 'PartsSentToArea',
    AcceptedFullyByArea = 'AcceptedFullyByArea',
    AcceptedFullyByGeneralAgent = 'AcceptedFullyByGeneralAgent',
    AcceptedPartlyByArea = 'AcceptedPartlyByArea',
    AcceptedPartlyByGeneralAgent = 'AcceptedPartlyByGeneralAgent',
    DeclinedByArea = 'DeclinedByArea',
    DeclinedByGeneralAgent = 'DeclinedByGeneralAgent'
};
export enum ClaimDecisionClaimStateArea {
    SentToArea = 'SentToArea',
    ReturnedToGeneralAgent = 'ReturnedToGeneralAgent',
    PartsRequestedFromGeneralAgent = 'PartsRequestedFromGeneralAgent',
    PartsSentToArea = 'PartsSentToArea',
    AcceptedFullyByArea = 'AcceptedFullyByArea',
    AcceptedPartlyByArea = 'AcceptedPartlyByArea',
    DeclinedByArea = 'DeclinedByArea'
};
export enum ClaimDecisionClaimDisplayModeSp {
    Edit = 'Edit',
    Decided = 'Decided',
    PartsRequested = 'PartsRequested',
    ReturnedToSP = 'ReturnedToSP'
};
export enum ClaimDecisionClaimDisplayModeArea {
    Edit = 'Edit',
    Submitted = 'Submitted',
    Returned = 'Returned'
};
export enum ClaimDecisionClaimType {
    BeforeDelivery = 'BeforeDelivery',
    WarrantyClaim = 'WarrantyClaim',
    FairDeal = 'FairDeal',
    LoadBearing = 'LoadBearing',
    SparePartClaim = 'SparePartClaim',
    ContractAgreement = 'ContractAgreement',
    RecallAction = 'RecallAction',
    ClaimOnDelivery = 'ClaimOnDelivery'
};
export enum ClaimDecisionClaimProcess {
    MaterialDelivery = 'MaterialDelivery',
    CreditNote = 'CreditNote'
};
export enum ClaimDecisionClaimProcessArea {
    MaterialDelivery = 'MaterialDelivery',
    CreditNote = 'CreditNote'
};
export enum ClaimDecisionProcessingFeeApprovedStateArea {
    Accepted = 'Accepted',
    DeclinedMissingProductionData = 'DeclinedMissingProductionData',
    DeclinedIncompleteBasicData = 'DeclinedIncompleteBasicData',
    DeclinedMissingDataFile = 'DeclinedMissingDataFile',
    DeclinedSubmissionAfterDeadline = 'DeclinedSubmissionAfterDeadline',
    DeclinedMaterialIssue = 'DeclinedMaterialIssue',
    DeclinedAttachmentMissing = 'DeclinedAttachmentMissing',
    DeclinedTextAndCodingNotIdentical = 'DeclinedTextAndCodingNotIdentical',
    DeclinedOther = 'DeclinedOther'
};
export enum ClaimDecisionProcessingFeeApprovedStateGa {
    Accepted = 'Accepted',
    DeclinedMissingProductionData = 'DeclinedMissingProductionData',
    DeclinedIncompleteBasicData = 'DeclinedIncompleteBasicData',
    DeclinedMissingDataFile = 'DeclinedMissingDataFile',
    DeclinedSubmissionAfterDeadline = 'DeclinedSubmissionAfterDeadline',
    DeclinedMaterialIssue = 'DeclinedMaterialIssue',
    DeclinedAttachmentMissing = 'DeclinedAttachmentMissing',
    DeclinedTextAndCodingNotIdentical = 'DeclinedTextAndCodingNotIdentical',
    DeclinedOther = 'DeclinedOther'
};
export enum ClaimDecisionClaimDisplayState {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};
export enum ClaimDecisionClaimDisplayStateArea {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};



