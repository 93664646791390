import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'cm-ticket-number',
  imports: [
    CommonModule,
    MatFormFieldModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    DsSpacingModule,
    MatTooltipModule,
  ],
  templateUrl: './ticket-number.component.html',
  styleUrl: './ticket-number.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketNumberComponent {
  @Input()
  case_number: FormControl;
}
