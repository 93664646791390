<mat-card class="full-height" fxLayout="column">
  <h5>{{ 'datepicker_presets.custom_range' | translate }}</h5>
  <mat-divider></mat-divider>
  <div class="scroll-container-vertical min-height" fxFlex>
    <button
      mat-button
      class="full-width cut-text text-left d-block"
      *ngFor="let preset of presets"
      (click)="selectRange(preset)"
    >
      {{ preset.title | translate }}
    </button>
  </div>
</mat-card>
