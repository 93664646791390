/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeliveryDecisionSparePartUpdate } from './deliveryDecisionSparePartUpdate';
import { Attachment } from './attachment';


export interface DeliveryDecisionUpdate { 
    
    action: DeliveryDecisionUpdateAction;
    
    claim_id: number;
    
    claim_number_ga: string;
    
    description_ga?: string;
    
    decision?: string;
    
    credit_note?: string;
    
    case_number?: string;
    
    claim_process_type?: DeliveryDecisionUpdateClaimProcessType;
    
    attachments?: Array<Attachment>;
    
    spare_parts?: Array<DeliveryDecisionSparePartUpdate>;
}
export enum DeliveryDecisionUpdateAction {
    Sent = 'Sent',
    Accept = 'Accept',
    Decline = 'Decline',
    SparePartsRequested = 'SparePartsRequested'
};
export enum DeliveryDecisionUpdateClaimProcessType {
    MaterialDelivery = 'MaterialDelivery',
    CreditNote = 'CreditNote'
};



