<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  class="full-height"
  gaCategoryGroup="ClaimDesk"
>
  <div fxFlex="0 0 auto">
    <h1 fxLayout="row wrap" fxLayoutAlign="space-between baseline">
      {{ 'claim-management.dashboard.claimdesk' | translate }}
      <div fxLayout>
        <button
          gaClickListener="DownloadExportedData"
          mat-button
          page-action
          (click)="downloadExportedData()"
          [disabled]="exportRequestPending"
        >
          <mat-icon>cloud_download</mat-icon>
          {{ 'claim-management.dashboard.export' | translate }}
          <mat-progress-spinner
            [mode]="'indeterminate'"
            *ngIf="exportRequestPending"
            [diameter]="15"
          ></mat-progress-spinner>
        </button>
        <button
          gaClickListener="CreateClaim"
          mat-button
          page-action
          (click)="createClaim()"
        >
          <mat-icon>add</mat-icon>
          {{ 'claim-management.create_claim' | translate }}
        </button>
        <button
          *ngIf="claimUserContext?.claim_on_delivery_access"
          gaClickListener="CreateClaimOnDelivery"
          mat-button
          page-action
          [routerLink]="['/delivery']"
        >
          <mat-icon>add</mat-icon>
          {{ 'claim-management.create_claim_on_delivery' | translate }}
        </button>
      </div>
    </h1>
  </div>

  <p *ngIf="!!customFilterMessage">
    <span [innerHtml]="customFilterMessage"></span>
    &nbsp;
    <a [routerLink]="['/claimdesk/all']">{{
      'claim-management.dashboard.custom_filter.reset_filter' | translate
    }}</a>
  </p>

  <ds-table *pdLet="summary$ | async as summary" class="data-table">
    <ds-filter
      (resetFilter)="filterForm.reset()"
      [formGroup]="filterForm"
      gaCategoryGroup="ClaimDeskFilter"
    >
      <mat-form-field *dsFilterItem="filterForm.get('end_date')">
        <mat-label>{{
          (dateType === 'Repair'
            ? 'claim-management.dashboard.repairDate'
            : 'claim-management.dashboard.created'
          ) | translate
        }}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            placeholder="Start date"
            formControlName="start_date"
            data-cy="filter.start_date"
            (click)="picker.open()"
            readonly
          />
          <input
            matEndDate
            placeholder="End date"
            formControlName="end_date"
            data-cy="filter.end_date"
            (click)="picker.open()"
            readonly
          />
        </mat-date-range-input>

        <div matIconSuffix class="nowrap-text">
          <mat-datepicker-toggle
            [for]="picker"
            [gaClickListener]="
              dateType === 'Repair' ? 'RepairDateClick' : 'CreatedDateClick'
            "
          ></mat-datepicker-toggle>
          <mat-datepicker-toggle
            (click)="
              filterForm.controls.start_date.reset();
              filterForm.controls.end_date.reset()
            "
          >
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
        </div>

        <mat-date-range-picker
          #picker
          [calendarHeaderComponent]="DsPresetCalenderHeaderComponent"
        ></mat-date-range-picker>
        <mat-hint *ngIf="!customFilterMessage"
          ><a
            gaClickListener="SetRepairDatePickerMode"
            [ngClass]="{
              active: dateType === 'Repair',
            }"
            (click)="dateType = 'Repair'"
            >{{ 'claim-management.dashboard.repairDate' | translate }}</a
          >
          |
          <a
            gaClickListener="SetCreateDatePickerMode"
            [ngClass]="{
              active: dateType === 'Create',
            }"
            (click)="dateType = 'Create'"
            >{{ 'claim-management.dashboard.created' | translate }}</a
          ></mat-hint
        >
      </mat-form-field>
      <ng-container *ngIf="!customFilterMessage">
        <mat-form-field
          gaClickListener="ClaimNumberClick"
          data-cy="ClaimNumberFilter"
          *dsFilterItem="filterForm.get('claim_number')"
        >
          <mat-label>{{
            'claim-management.dashboard.claim_number_placeholder' | translate
          }}</mat-label>
          <input
            matInput
            formControlName="claim_number"
            data-cy="filter.claim_number"
            autocomplete="off"
          />
        </mat-form-field>

        <mat-form-field
          gaClickListener="EquipmentNumberClick"
          *dsFilterItem="filterForm.get('equipment_number')"
        >
          <mat-label>{{
            'claim-management.dashboard.number' | translate
          }}</mat-label>
          <input
            matInput
            formControlName="equipment_number"
            data-cy="filter.equipment_number"
          />
          <mat-icon
            inline
            matIconSuffix
            [matTooltip]="
              'claim-management.dashboard.number_filter_hint' | translate
            "
            >help_outline</mat-icon
          >
        </mat-form-field>
        <div *dsFilterItem="filterForm.get('equipment_type')">
          <ds-filter-input
            *ngIf="availableProductTypes?.length"
            [isMultiChoiceDisabled]="true"
            [data]="availableProductTypes"
            [label]="'claim-management.dashboard.productGroup' | translate"
            [control]="$any(filterForm.get('equipment_type'))"
            gaClickListener="ProductGroupClick"
            data-cy="filter.equipment_type"
          >
          </ds-filter-input>
        </div>

        <ng-container
          *ngIf="
            partnerType === PartnerTypes.GENERAL_AGENT &&
            claimUserContext?.claim_on_delivery_access
          "
        >
          <mat-form-field
            *dsFilterItem="filterForm.get('delivery_note_number')"
            gaClickListener="DeliveryNoteNumberClick"
          >
            <mat-label>{{
              'claim-management.dashboard.delivery_note_number' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="delivery_note_number"
              data-cy="filter.delivery_note_number"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="partnerType === PartnerTypes.GENERAL_AGENT">
          <mat-form-field
            gaClickListener="ServicePartnerClick"
            *dsFilterItem="filterForm.get('service_partner')"
          >
            <mat-label>{{
              'claim-management.generalClaim.selectServicePartner' | translate
            }}</mat-label>
            <mat-select
              name="servicePartnerControl"
              formControlName="service_partner"
              data-cy="filter.service_partner"
            >
              <mat-option [value]="undefined">
                {{ 'claim-management.dashboard.select_all' | translate }}
              </mat-option>
              <mat-option [value]="-1" data-cy="filter.service_partner.me">
                {{
                  'claim-management.generalClaim.my_company'
                    | translate
                      : {
                          company_name: this.company_name,
                        }
                }}
              </mat-option>
              <mat-option
                *ngFor="let partner of servicePartners$ | async"
                [value]="partner.id"
                [attr.data-cy]="['filter.service_partner.' + partner.id]"
              >
                {{ partner.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <div *dsFilterItem="filterForm.get('claim_type')">
          <ds-filter-input
            *ngIf="availableClaimTypes?.length"
            [data]="availableClaimTypes"
            [label]="'claim-management.dashboard.claimType' | translate"
            [control]="$any(filterForm.get('claim_type'))"
            gaClickListener="ClaimTypeClick"
          >
          </ds-filter-input>
        </div>

        <mat-form-field
          *dsFilterItem="filterForm.get('internal_note')"
          gaClickListener="InternalNoteClick"
        >
          <mat-label>{{
            'claim-management.dashboard.internal_note' | translate
          }}</mat-label>
          <input
            matInput
            formControlName="internal_note"
            data-cy="filter.internal_note"
          />
        </mat-form-field>
      </ng-container>
    </ds-filter>

    <ng-container
      *ngIf="
        dataSourceData && displayColumnsSettings?.displayColumns && !loading;
        else loadingTmpl
      "
    >
      <table
        *ngIf="dataSourceData?.data?.length; else noResult"
        mat-table
        [dataSource]="dataSourceData"
        matSort
        [matSortDirection]="sortDirection"
        [matSortActive]="sortActive"
        (matSortChange)="getData()"
      >
        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.equipmentNumber
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByEquipmentNumber"
          >
            {{ 'claim-management.dashboard.equipmentNumber' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            <a
              *ngIf="element.equipment_number; else noEquipmentNumber"
              [href]="getEquipmentLink(element.equipment_number)"
              target="_blank"
              matTooltip="{{
                'claim-management.dashboard.go_to_equipment_screen' | translate
              }}"
            >
              {{ element.equipment_number }}
            </a>
            <ng-template #noEquipmentNumber>{{
              'general.n_a' | translate
            }}</ng-template>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.deliveryNote"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByDeliveryNoteNumber"
          >
            {{ 'claim-management.dashboard.deliveryNoteNumber' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{ element.delivery_note || ('general.n_a' | translate) }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.createdOn"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByCreateDate"
          >
            {{ 'claim-management.dashboard.createdOn' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{
              (element.created_on | date: 'shortDate') ||
                ('general.n_a' | translate)
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.decisionDate"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByDecisionDate"
          >
            {{ 'claim-management.dashboard.decisionDate' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{
              (element.decision_date | date: 'shortDate') ||
                ('general.n_a' | translate)
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.decisionDateArea
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByDecisionDateArea"
          >
            {{ 'claim-management.dashboard.decisionDateArea' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{
              (element.decision_date_area | date: 'shortDate') ||
                ('general.n_a' | translate)
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.productType"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByProductType"
          >
            {{ 'claim-management.dashboard.productType' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{ element.product_type || ('general.n_a' | translate) }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.servicePartnerName
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByServicePartnerName"
          >
            {{ 'claim-management.dashboard.service_partner_name' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{ element.service_partner_name || ('general.n_a' | translate) }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.state"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByState"
          >
            {{ 'claim-management.dashboard.state' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            <span>
              {{
                element.state
                  ? ('claim-management.dashboard.' + element.state
                    | translate
                    | titlecase)
                  : ('general.n_a' | translate)
              }}</span
            >
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.stateSPtoGA"
        >
          <th
            *matHeaderCellDef
            gaClickListener="SortByStateSPtoGA"
            mat-header-cell
            mat-sort-header
          >
            {{ 'claim-management.dashboard.state_sp_to_ga' | translate }}
          </th>
          <td *matCellDef="let element" class="min-width-cell" mat-cell>
            <span *ngIf="element.state_sp_to_ga as state">
              {{
                'claim-management.dashboard.' + state | translate | titlecase
              }}
              <ng-container
                *ngIf="
                  (state ===
                    ClaimdeskDecisionRowStateSpToGa.PartsRequestedFromSP &&
                    element.days_since_material_requested) ||
                  (state ===
                    ClaimdeskDecisionRowStateSpToGa.InformationRequestedFromSP &&
                    element.days_since_info_requested)
                "
              >
                ({{
                  'claim-management.days_ago'
                    | translate
                      : {
                          days:
                            state ===
                            ClaimdeskDecisionRowStateSpToGa.PartsRequestedFromSP
                              ? element.days_since_material_requested
                              : element.days_since_info_requested,
                        }
                }})
              </ng-container>
            </span>
            <span *ngIf="!element.state_sp_to_ga">
              {{ 'general.n_a' | translate }}
            </span>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.stateAreaGA"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByStateAREAtoGA"
          >
            {{ 'claim-management.dashboard.state_area_ga' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            <span>
              {{
                element.state_area_ga
                  ? ('claim-management.dashboard.' + element.state_area_ga
                    | translate
                    | titlecase)
                  : ('general.n_a' | translate)
              }}</span
            >
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.totalRequested
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByRequestedSum"
            class="text-right"
            arrowPosition="before"
          >
            {{ 'claim-management.dashboard.requestedSum' | translate }}
            <mat-hint>
              {{ currency }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.total_requested ? element.total_requested : '0')
                | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary"
              >{{
                (summary.total_sum_sp
                  ? summary.total_sum_sp
                  : summary.total_sum_ga || 0
                ) | number: '0.2-2'
              }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.totalApproved"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByApprovedSum"
            class="text-right"
            arrowPosition="before"
          >
            {{ 'claim-management.dashboard.approvedSum' | translate }}
            <mat-hint>
              {{ currency }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.total_approved ? element.total_approved : '0')
                | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary">{{
              (summary.total_sum_approved_ga
                ? summary.total_sum_approved_ga
                : summary.total_sum_approved_area || 0
              ) | number: '0.2-2'
            }}</strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.totalSP"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByRequestedSP"
            class="text-right"
            arrowPosition="before"
          >
            {{ 'claim-management.dashboard.requested_sp' | translate }}
            <mat-hint>
              {{ currencySp }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.total_sp ? element.total_sp : '0') | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary"
              >{{ summary.total_sum_sp | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.totalApprovedGA
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByAcceptedSP"
            class="text-right"
            arrowPosition="before"
          >
            {{ 'claim-management.dashboard.accepted_sp' | translate }}
            <mat-hint>
              {{ currencySp }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.total_approved_ga ? element.total_approved_ga : '0')
                | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary"
              >{{ summary.total_sum_approved_ga | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.totalGA"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByRequestedFromAREA"
            class="text-right"
            arrowPosition="before"
          >
            {{ 'claim-management.dashboard.requested_from_area' | translate }}
            <mat-hint>
              {{ currency }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.total_ga ? element.total_ga : '0') | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary"
              >{{ summary.total_sum_ga | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.totalApprovedArea
          "
        >
          <th
            arrowPosition="before"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByAcceptedFromAREA"
            class="text-right"
          >
            {{ 'claim-management.dashboard.accepted_from_area' | translate }}
            <mat-hint>
              {{ currency }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.total_approved_area ? element.total_approved_area : '0')
                | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary"
              >{{ summary.total_sum_approved_area | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.repairDate"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByRepairDate"
          >
            {{ 'claim-management.dashboard.repairDate' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{
              (element.repair_date | date: 'shortDate') ||
                ('general.n_a' | translate)
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.claimNumber"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByClaimNumber"
          >
            {{ 'claim-management.dashboard.claimNumber' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            <div
              matTooltip="{{
                (element.sibling_count
                  ? 'claim-management.linked_claims_tooltip'
                  : 'claim-management.openClaim'
                ) | translate: { linkedCount: element.sibling_count }
              }}"
            >
              <a
                [routerLink]="getDetailRouterLink(element)"
                data-cy="claimNumberCell"
              >
                {{ element.claim_number }}
              </a>
              <span *ngIf="element.sibling_count" class="count">{{
                element.sibling_count
              }}</span>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ 'general.total' | translate }}
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.claimNumberSP"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByClaimNumberSP"
          >
            {{ 'claim-management.dashboard.claim_number_sp' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            <a
              *ngIf="!element.claim_number; else noLink"
              [routerLink]="getDetailRouterLink(element)"
              matTooltip="{{ 'claim-management.openClaim' | translate }}"
            >
              {{ element.claim_number_sp }}
            </a>
            <ng-template #noLink>
              {{ element.claim_number_sp || ('general.n_a' | translate) }}
            </ng-template>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.claimNumberArea
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByClaimNumberAREA"
          >
            {{ 'claim-management.dashboard.claim_number_area' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            <a
              *ngIf="
                !element.claim_number && !element.claim_number_sp;
                else noLink
              "
              [routerLink]="getDetailRouterLink(element)"
              matTooltip="{{ 'claim-management.openClaim' | translate }}"
              >{{ element.claim_number_area }}</a
            >
            <ng-template #noLink>
              {{ element.claim_number_area || ('general.n_a' | translate) }}
            </ng-template>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.licensePlate"
        >
          <th
            *matHeaderCellDef
            gaClickListener="SortByLicenseNumber"
            mat-header-cell
            mat-sort-header
          >
            {{ 'claim-management.dashboard.licensePlate' | translate }}
          </th>
          <td *matCellDef="let element" class="min-width-cell" mat-cell>
            {{ element.license_plate || ('general.n_a' | translate) }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.requestedProcessingFeeGA
          "
        >
          <th
            arrowPosition="before"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByRequestedProcessingFeeGA"
            class="text-right"
          >
            {{
              'claim-management.dashboard.requested_processing_fee_ga'
                | translate
            }}
            <mat-hint>
              {{ currency }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.requested_processing_fee_ga
                ? element.requested_processing_fee_ga
                : '0'
              ) | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary">
              {{ summary.sum_requested_processing_fee_ga | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.requestedProcessingFeeSP
          "
        >
          <th
            arrowPosition="before"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByRequestedProcessingFeeSP"
            class="text-right"
          >
            {{
              'claim-management.dashboard.requested_processing_fee_sp'
                | translate
            }}
            <mat-hint>
              {{ currencySp }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.requested_processing_fee_sp
                ? element.requested_processing_fee_sp
                : '0'
              ) | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary">
              {{ summary.sum_requested_processing_fee_sp | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.approvedProcessingFeeArea
          "
        >
          <th
            arrowPosition="before"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByApprovedProcessingFeeArea"
            class="text-right"
          >
            {{
              'claim-management.dashboard.approved_processing_fee_area'
                | translate
            }}
            <mat-hint>
              {{ currency }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>{{
              (element.approved_processing_fee_area
                ? element.approved_processing_fee_area
                : '0'
              ) | number: '0.2-2'
            }}</strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary">
              {{ summary.sum_approved_processing_fee_area | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.approvedProcessingFeeGA
          "
        >
          <th
            arrowPosition="before"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByApprovedProcessingFeeGA"
            class="text-right"
          >
            {{
              'claim-management.dashboard.approved_processing_fee_ga'
                | translate
            }}
            <mat-hint>
              {{ currencySp }}
            </mat-hint>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell text-right"
          >
            <strong>
              {{
                (element.approved_processing_fee_ga
                  ? element.approved_processing_fee_ga
                  : '0'
                ) | number: '0.2-2'
              }}
            </strong>
          </td>
          <td mat-footer-cell *matFooterCellDef class="text-right">
            <strong *ngIf="summary">
              {{ summary.sum_approved_processing_fee_ga | number: '0.2-2' }}
            </strong>
          </td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns
              .approvedProcessingFeeStateArea
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByApprovedProcessingFeeStateArea"
          >
            {{
              'claim-management.dashboard.approved_processing_fee_state_area'
                | translate
            }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell normal-white-space"
          >
            <span>
              {{
                element.approved_processing_fee_state_area
                  ? ('claim-management.summary.handling_fee_approved_state.' +
                      element.approved_processing_fee_state_area | translate)
                  : ('general.n_a' | translate)
              }}
            </span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.approvedProcessingFeeStateGA
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByApprovedProcessingFeeStateGA"
          >
            {{
              'claim-management.dashboard.approved_processing_fee_state_ga'
                | translate
            }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell normal-white-space"
          >
            <span>
              {{
                element.approved_processing_fee_state_ga
                  ? ('claim-management.summary.handling_fee_approved_state.' +
                      element.approved_processing_fee_state_ga | translate)
                  : ('general.n_a' | translate)
              }}
            </span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.creditNotes"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByCreditNote"
          >
            {{ 'claim-management.dashboard.credit_notes' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell long-text"
            [matTooltip]="element.credit_notes"
            [matTooltipDisabled]="element.credit_notes?.length < 30"
          >
            {{ element.credit_notes || ('general.n_a' | translate) }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.creditNotesArea
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByCreditNoteArea"
          >
            {{ 'claim-management.dashboard.credit_notes_area' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell long-text"
            [matTooltip]="element.credit_notes_area"
            [matTooltipDisabled]="element.credit_notes_area?.length < 30"
          >
            {{ element.credit_notes_area || ('general.n_a' | translate) }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.internalNote"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByInternalNote"
          >
            {{ 'claim-management.dashboard.internal_note' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell long-text"
            [matTooltip]="element.internal_note"
            [matTooltipDisabled]="element.internal_note?.length < 30"
          >
            {{ element.internal_note || ('general.n_a' | translate) }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="displayColumnsSettings.palfingerColumns.claimType"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortByClaimType"
          >
            {{ 'claim-management.dashboard.claimType' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-width-cell long-text"
          >
            {{ 'claim-management.claimType.' + element.claim_type | translate }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container
          [matColumnDef]="
            displayColumnsSettings.palfingerColumns.submittedToGaOn
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            gaClickListener="SortBySubmittedToGaOn"
          >
            {{ 'claim-management.dashboard.submitted_to_ga_on' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="min-width-cell">
            {{
              (element.submitted_to_ga_on | date: 'shortDate') ||
                ('general.n_a' | translate)
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            <ds-table-settings
              gaClickListener="TableSettingsClick"
              [defaultSettings]="displayColumnsSettings.displayColumns"
              [settingsKey]="'claim-management.claimdesk.table_settings.v2'"
              (userSettings)="setUserSettings($event)"
            >
            </ds-table-settings>
          </th>
          <td
            class="mat-action-cell"
            mat-cell
            *matCellDef="let element; let i = index"
          >
            <button
              data-cy="three-dots-button"
              mat-icon-button
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <span
                [matTooltipDisabled]="element.can_delete"
                [matTooltip]="
                  'claim-management.dashboard.delete_hint' | translate
                "
              >
                <button
                  (click)="removeRow(element)"
                  [disabled]="!element.can_delete"
                  gaClickListener="RemoveRow"
                  data-cy="delete-button"
                  mat-menu-item
                >
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'general.delete' | translate }}</span>
                </button>
              </span>

              <a
                gaClickListener="GoToPrintClick"
                target="_blank"
                mat-menu-item
                (click)="getPrintReport(element)"
              >
                <mat-icon>print</mat-icon>
                <span>{{ 'general.print' | translate }}</span>
              </a>
            </mat-menu>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="
            displayColumnsSettings.displayColumns | displayColumns;
            sticky: true
          "
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: displayColumnsSettings.displayColumns | displayColumns
          "
          [class.recent]="row.is_recent"
        ></tr>
        <tr
          mat-footer-row
          *matFooterRowDef="
            displayColumnsSettings.displayColumns | displayColumns;
            sticky: true
          "
        ></tr>
      </table>
    </ng-container>
    <ds-table-footer>
      <mat-paginator
        gaClickListener="PaginatorClick"
        fxFlex
        showFirstLastButtons
        [length]="paginator?.length || 0"
        [pageIndex]="paginator?.pageIndex || 0"
        [pageSizeOptions]="[10, 20, 100]"
        (page)="getData()"
        [cdkStorePageSize]="'claimdesk_table_pagesize'"
      ></mat-paginator>
    </ds-table-footer>
  </ds-table>
</div>

<ng-template #loadingTmpl>
  <ds-table-loading [numberOfHeaders]="8" [numberOfRows]="10">
  </ds-table-loading>
</ng-template>
<ng-template #noResult>
  <ds-placeholder
    type="no_data"
    [customHeadline]="'There are no claims'"
  ></ds-placeholder>
</ng-template>
