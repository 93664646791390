/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalSparePartDecision } from './externalSparePartDecision';
import { InternalSparePartDecision } from './internalSparePartDecision';
import { Attachment } from './attachment';
import { ErrorCodings } from './errorCodings';


export interface ClaimDecisionSave { 
    /**
     * Enumeration of GA decisions when closing claims
     */
    
    action: ClaimDecisionSaveAction;
    
    claim_id: number;
    
    first_installation_date?: string;
    
    operation_hours?: number;
    
    claim_number: string;
    
    fault_causing_part_id?: number;
    
    fault_causing_part_code?: string;
    
    fault_serial_number?: string;
    
    decision?: string;
    
    error_codings?: ErrorCodings;
    
    description_addition?: string;
    
    local_user_date: string;
    /**
     * Only filled when action is request information, text from GA to SP what needs to be additionally added to claim
     */
    
    required_information?: string;
    
    attachments?: Array<Attachment>;
    
    internal_spare_parts?: Array<InternalSparePartDecision>;
    
    external_spare_parts?: Array<ExternalSparePartDecision>;
    
    working_hours_sp: number;
    
    working_hour_price_sp: number;
    
    working_hours_ga: number;
    
    working_hour_price_ga: number;
    
    working_hours_approved_ga: number;
    
    working_hour_price_approved_ga: number;
    
    processing_fee_approved_ga?: number;
    
    processing_fee_ga?: number;
    
    total_price_sp: number;
    
    total_price_ga: number;
    
    total_price_approved_ga: number;
    
    processing_fee_approved_state_ga?: ClaimDecisionSaveProcessingFeeApprovedStateGa;
    
    processing_fee_decision?: string;
    
    recall_working_hours_modified_reason?: string;
    
    repair_working_hours_modified_reason?: string;
    
    case_number?: string;
}
export enum ClaimDecisionSaveAction {
    Save = 'Save',
    Send = 'Send',
    RequestMaterials = 'RequestMaterials',
    AcceptFully = 'AcceptFully',
    AcceptPartially = 'AcceptPartially',
    Decline = 'Decline',
    RequestInformation = 'RequestInformation'
};
export enum ClaimDecisionSaveProcessingFeeApprovedStateGa {
    Accepted = 'Accepted',
    DeclinedMissingProductionData = 'DeclinedMissingProductionData',
    DeclinedIncompleteBasicData = 'DeclinedIncompleteBasicData',
    DeclinedMissingDataFile = 'DeclinedMissingDataFile',
    DeclinedSubmissionAfterDeadline = 'DeclinedSubmissionAfterDeadline',
    DeclinedMaterialIssue = 'DeclinedMaterialIssue',
    DeclinedAttachmentMissing = 'DeclinedAttachmentMissing',
    DeclinedTextAndCodingNotIdentical = 'DeclinedTextAndCodingNotIdentical',
    DeclinedOther = 'DeclinedOther'
};



