<mat-form-field
  class="full-width"
  [matTooltip]="'claim-management.ticket_number_example' | translate"
  ><mat-label>{{ 'claim-management.ticket_num' | translate }}</mat-label
  ><span class="prefix" matPrefix>CAS -</span
  ><input
    matInput
    type="text"
    [formControl]="case_number"
    pattern="[a-zA-Z0-9-]*"
    [maxLength]="16"
    class="uppercase"
  /><mat-error
    >{{ 'claim-management.only_letters_numbers_and_dash' | translate }}
  </mat-error>
</mat-form-field>
